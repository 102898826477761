<template>
  <label :class="labelClass"><slot></slot></label>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  small?: string
}

/**
 * @param {attribute=} small - Smaller bolder version
 */

@Options({
  name: 'Input-label'
})

export default class InputLabel extends Vue.with(Props) {
  get labelClass () {
    const classes = {}
    classes['fs-14'] = true
    classes['blue-dark'] = true
    classes['d-b'] = true
    classes['tt-uppercase'] = true

    if (this.small === '') {
      classes['fs-12'] = true
      classes['fw-700'] = true
    }
    return classes
  }
}
</script>
