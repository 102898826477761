
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

interface MenuItem {
  label: string;
  icon: string;
  action: string;
  class?: string;
}

class Props {
  menu?: Array<MenuItem>;
}

@Options({
  name: 'More-menu',
  components: {
    IconButton
  },
  emits: ['menu-action']
})

export default class MoreMenu extends Vue.with(Props) {
  menuIsActive = false

  clickMenuItem (action: string) {
    this.$emit('menu-action', action)
  }

  blurMenu (ev: Event) {
    if (ev['relatedTarget'] === null || !ev['relatedTarget'].hasAttribute('data-link')) {
      this.menuIsActive = false
    }
  }

  toggleMenu () {
    if (this.menuIsActive) {
      this.menuIsActive = false
    } else {
      this.menuIsActive = true
    }
  }
}
