
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item!: Upload;
}

@Options({
  name: 'New-inquiry-upload',
  components: {
    IconButton,
    QrCode,
    VuePdfEmbed
  },
  emits: ['remove-new-inquiry-upload']
})

export default class NewInquiryUpload extends Vue.with(Props) {
  isPdf () {
    if (this.item['imageContent']) {
      return this.item!['imageContent'].includes('application/pdf')
    } else if (this.item['file']) {
      if (this.item['file'].includes('blob')) {
        return this.item!['title'].includes('.pdf')
      } else {
        return this.item!['file'].includes('application/pdf')
      }
    }
  }

  removeUpload () {
    if (this.item) {
      this.$emit('remove-new-inquiry-upload', this.item.id)
    }
  }

  displayDeleteButton () {
    return this.$route.name !== 'Inquiry'
  }
}
