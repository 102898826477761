
import { Options, Vue, prop } from 'vue-class-component'

class Props {
  value?: string
  placeholder?: string
  type = prop<string>({ default: 'text' })
  modelValue?: string
}

@Options({
  name: 'Input-text',
  emits: ['update:modelValue']
})

/**
 * HTML Input
 *
 * @param {string=} value
 * @param {string=} placeholder
 * @param {string=} type - Default: text
 * @param {string=} modelValue - v-model reference https://v3.vuejs.org/guide/migration/v-model.html#_3-x-syntax
 */
export default class InputText extends Vue.with(Props) {
  get typeValue () {
    return this.type
  }

  get placeholderValue () {
    return this.placeholder
  }

  get inputValue () {
    if (this.modelValue) {
      return this.modelValue
    } else {
      return this.value
    }
  }
}
