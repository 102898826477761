
import { Options, Vue } from 'vue-class-component'
import NewInquiryUpload from '@/02-molecules/New-inquiry-upload.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { postUploadsFile } from '@/api/uploads/files-api'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

class Props {
  newInquiryUploads?: Array<Upload>;
}

@Options({
  name: 'New-inquiry-uploads',
  components: {
    LoadingDots,
    QrCode,
    NewInquiryUpload
  },
  emits: ['add-new-inquiry-upload', 'remove-new-inquiry-upload', 'add-qr-file']
})

export default class NewInquiryUploads extends Vue.with(Props) {
  isLoading = false

  addQrFile (file: any, type: 'default' | 'cns') {
    this.$emit('add-qr-file', file, type)
  }

  removeNewInquiryUpload (id: string) {
    const inputUpload: any = this.$refs['new-inquiry-uploads-input']
    inputUpload.value = ''
    this.$emit('remove-new-inquiry-upload', id)
  }

  newUpload = {
    title: '',
    file: '',
    id: '',
    fileType: 'default'
  }

  createFile (ev: Event) {
    if (ev.target) {
      this.isLoading = true

      this.newUpload = {
        title: ev.target['files'][0].name,
        file: URL.createObjectURL(ev.target['files'][0]),
        id: '',
        fileType: 'default'
      }

      const file = new FormData()
      file.append('title', this.newUpload.title)
      file.append('file', ev.target['files'][0])
      file.append('fileType', 'default')

      this.uploadFile(file)
    }
  }

  errorHandlerService = inject(ErrorHandlerKey)
  uploadFile (file: any) {
    postUploadsFile(file)
      .then((response) => {
        this.newUpload['id'] = response.data.id
        this.$emit('add-new-inquiry-upload', this.newUpload)

        this.newUpload = {
          title: '',
          file: '',
          id: '',
          fileType: 'default'
        }

        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
