
import { Options, Vue } from 'vue-class-component'
import Search from '@/02-molecules/Search.vue'
import IconButton from '@/01-atoms/Icon-button.vue'

@Options({
  name: 'Header-search',
  components: {
    Search,
    IconButton
  }
})
export default class HeaderSearch extends Vue {}
