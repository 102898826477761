<template>
  <input
      :type="typeValue"
      :placeholder="placeholderValue"
      :value="inputValue"
      @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'

class Props {
  value?: string
  placeholder?: string
  type = prop<string>({ default: 'text' })
  modelValue?: string
}

@Options({
  name: 'Input-text',
  emits: ['update:modelValue']
})

/**
 * HTML Input
 *
 * @param {string=} value
 * @param {string=} placeholder
 * @param {string=} type - Default: text
 * @param {string=} modelValue - v-model reference https://v3.vuejs.org/guide/migration/v-model.html#_3-x-syntax
 */
export default class InputText extends Vue.with(Props) {
  get typeValue () {
    return this.type
  }

  get placeholderValue () {
    return this.placeholder
  }

  get inputValue () {
    if (this.modelValue) {
      return this.modelValue
    } else {
      return this.value
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  @include brandon-grotesque;
  appearance: none;
  background: transparent;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid $blue-dark;
  border-left: 0;
  border-radius: 0;
  color: $grey-dark;
  display: block;
  font-size: 16px;
  padding: 6px 0;
  width: 100%;

  &::placeholder {
    @include brandon-grotesque;
    color: $grey-dark;
  }

  &:focus {
    border-bottom: 1px solid $blue-light;
    outline: none;
  }
}

.form.has-errors {
  input[type="text"]:invalid,
  input[type="password"]:invalid,
  input[type="email"]:invalid,
  input[type="number"]:invalid {
    border-bottom: 1px solid $red;
  }
}
</style>
