<template>
  <div class="new-inquiry-uploads">
    <NewInquiryUpload
        @remove-new-inquiry-upload="removeNewInquiryUpload"
        v-for="item in newInquiryUploads"
        :key="item.id"
        :item="item"
        class="new-inquiry-uploads__upload"
    ></NewInquiryUpload>
    <div class="new-inquiry-upload mb-s ta-center">
      <div class="new-inquiry-upload__inner">
        <QrCode @add-qr-file="addQrFile" type="default" :load-qr="true" />
        <p>{{ $t('new-inquiry.upload-text') }}</p>
        <LoadingDots class="new-inquiry-upload__loading-dots" v-if="isLoading" />
        <label v-else class="new-inquiry-upload__label" for="inquiry">{{ $t('new-inquiry.upload-button') }}</label>
        <input id="inquiry" type="file" class="new-inquiry-upload__input" @change="createFile" ref="new-inquiry-uploads-input">
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NewInquiryUpload from '@/02-molecules/New-inquiry-upload.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { postUploadsFile } from '@/api/uploads/files-api'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

class Props {
  newInquiryUploads?: Array<Upload>;
}

@Options({
  name: 'New-inquiry-uploads',
  components: {
    LoadingDots,
    QrCode,
    NewInquiryUpload
  },
  emits: ['add-new-inquiry-upload', 'remove-new-inquiry-upload', 'add-qr-file']
})

export default class NewInquiryUploads extends Vue.with(Props) {
  isLoading = false

  addQrFile (file: any, type: 'default' | 'cns') {
    this.$emit('add-qr-file', file, type)
  }

  removeNewInquiryUpload (id: string) {
    const inputUpload: any = this.$refs['new-inquiry-uploads-input']
    inputUpload.value = ''
    this.$emit('remove-new-inquiry-upload', id)
  }

  newUpload = {
    title: '',
    file: '',
    id: '',
    fileType: 'default'
  }

  createFile (ev: Event) {
    if (ev.target) {
      this.isLoading = true

      this.newUpload = {
        title: ev.target['files'][0].name,
        file: URL.createObjectURL(ev.target['files'][0]),
        id: '',
        fileType: 'default'
      }

      const file = new FormData()
      file.append('title', this.newUpload.title)
      file.append('file', ev.target['files'][0])
      file.append('fileType', 'default')

      this.uploadFile(file)
    }
  }

  errorHandlerService = inject(ErrorHandlerKey)
  uploadFile (file: any) {
    postUploadsFile(file)
      .then((response) => {
        this.newUpload['id'] = response.data.id
        this.$emit('add-new-inquiry-upload', this.newUpload)

        this.newUpload = {
          title: '',
          file: '',
          id: '',
          fileType: 'default'
        }

        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
</script>
<style lang="scss" scoped>
.new-inquiry-uploads {
  display: flex;
  flex-wrap: wrap;
}

.new-inquiry-uploads__upload {
  width: 260px;
  margin-right: 10px;

  @include breakpoint(medium down) {
    margin-right: auto;
    margin-left: auto;
  }
}

.new-inquiry-upload {
  border: 4px dashed $blue-dark-10;
  border-radius: 10px;
  height: 360px;
  margin-right: 10px;
  width: 260px;

  @include breakpoint(large down) {
    height: auto;
    max-height: 360px;
  }

  @include breakpoint(medium down) {
    margin-right: auto;
    margin-left: auto;
  }
}

.new-inquiry-upload__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 28px;
  position: relative;
}

.new-inquiry-upload__label {
  @include brandon-grotesque-bold;
  appearance: none;
  background: $blue-dark;
  border: none;
  border-radius: 30px;
  color: $white;
  display: inline-block;
  font-size: 14px;
  font-family: sans-serif;
  padding: 8px 23px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

.new-inquiry-upload__input {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.new-inquiry-upload__loading-dots {
 height: 32px;
}
</style>
