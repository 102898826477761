<template>
  <div class="header-search">
    <div class="d-f">
      <h1 class="display-1 mr-m"><slot>Title</slot></h1>
<!--      <IconButton background="transparent" icon="info" round></IconButton>-->
    </div>
    <Search></Search>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Search from '@/02-molecules/Search.vue'
import IconButton from '@/01-atoms/Icon-button.vue'

@Options({
  name: 'Header-search',
  components: {
    Search,
    IconButton
  }
})
export default class HeaderSearch extends Vue {}
</script>
<style lang="scss" scoped>
.header-search {
  display: grid;
  grid-template-columns: 1fr 356px;
  align-items: flex-start;
  margin-bottom: 30px;

  @include breakpoint($responsive-navigation-breakpoint down) {
    display: none;
  }
}
</style>
