<template>
  <div :class="menuIsActive ? 'more-menu_container is-active' : 'more-menu_container'">
    <IconButton class="more-menu__button" @click="toggleMenu" @blur="blurMenu" icon="more-horizontal" background="blue-dark" round></IconButton>
    <ul class="more-menu__list">
      <li class="mb-s" v-for="item in menu" v-bind:key="item.label">
        <IconButton data-link
                    @click="clickMenuItem(item.action)"
                    :icon="item.icon" background="transparent"
                    :class="item.class">
          {{ $t(item.label) }}
        </IconButton>
      </li>
    </ul>
    <div style="display: none">
      {{ $t('inquiry.menu.add') }}
      {{ $t('inquiry.menu.edit') }}
      {{ $t('inquiry.menu.delete') }}
      {{ $t('inquiry.menu.save-as-pdf') }}
      {{ $t('inquiry.menu.print') }}
      {{ $t('inquiry.menu.share-by-email') }}
      {{ $t('document.menu.download-document') }}
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

interface MenuItem {
  label: string;
  icon: string;
  action: string;
  class?: string;
}

class Props {
  menu?: Array<MenuItem>;
}

@Options({
  name: 'More-menu',
  components: {
    IconButton
  },
  emits: ['menu-action']
})

export default class MoreMenu extends Vue.with(Props) {
  menuIsActive = false

  clickMenuItem (action: string) {
    this.$emit('menu-action', action)
  }

  blurMenu (ev: Event) {
    if (ev['relatedTarget'] === null || !ev['relatedTarget'].hasAttribute('data-link')) {
      this.menuIsActive = false
    }
  }

  toggleMenu () {
    if (this.menuIsActive) {
      this.menuIsActive = false
    } else {
      this.menuIsActive = true
    }
  }
}
</script>
<style lang="scss" scoped>
.more-menu_container {
  position: relative;
  border-radius: 20px;
  z-index: 1;

  &.is-active {
    background-color: white;
    box-shadow: 0 2px 4px $grey-light;
    padding: 38px 27px 15px;
  }
}

.more-menu__list {
  display: none;
  list-style: none;
  margin: 0;
  padding-left: 0;

  .is-active & {
    display: block;
  }
}

.more-menu__button {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: transform 0.2s ease;

  .is-active & {
    transform: rotate(-90deg);
  }

  &:focus {
    outline: none;
  }
}
</style>
