<template>
  <div class="new-inquiry-upload mb-s ta-center">
    <div class="new-inquiry-upload__media">
      <vue-pdf-embed :page="1" class="new-inquiry-upload__image" v-if="isPdf()" :source="item.file || item.imageContent" />
      <img v-else class="new-inquiry-upload__image" :src="item.file || item.imageContent" :alt="item.title">
      <IconButton @click="removeUpload"
                  v-if="displayDeleteButton()"
                  class="new-inquiry-upload__delete"
                  icon="x-circle"
                  background="transparent"
                  round />
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item!: Upload;
}

@Options({
  name: 'New-inquiry-upload',
  components: {
    IconButton,
    QrCode,
    VuePdfEmbed
  },
  emits: ['remove-new-inquiry-upload']
})

export default class NewInquiryUpload extends Vue.with(Props) {
  isPdf () {
    if (this.item['imageContent']) {
      return this.item!['imageContent'].includes('application/pdf')
    } else if (this.item['file']) {
      if (this.item['file'].includes('blob')) {
        return this.item!['title'].includes('.pdf')
      } else {
        return this.item!['file'].includes('application/pdf')
      }
    }
  }

  removeUpload () {
    if (this.item) {
      this.$emit('remove-new-inquiry-upload', this.item.id)
    }
  }

  displayDeleteButton () {
    return this.$route.name !== 'Inquiry'
  }
}
</script>
<style lang="scss" scoped>
.new-inquiry-upload {
  border: 4px dashed $blue-dark;
  border-radius: 10px;
  height: 360px;

  @include breakpoint(large down) {
    height: auto;
    max-height: 360px;
  }
}

.new-inquiry-upload__image {
  @include object-fit;
  border-radius: 10px;
  overflow: hidden;
}

.new-inquiry-upload__delete {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.new-inquiry-upload__media {
  height: 100%;
  position: relative;
}
</style>
